<template>
  <div class="layout-navbars-container">
    <BreadcrumbIndex />
    <!-- <TagsView v-if="setShowTagsView" /> -->
    <el-button icon="el-icon-switch-button" class="logout-btn" @click="logout" size="medium"
      >退出</el-button
    >
  </div>
</template>

<script>
import BreadcrumbIndex from "@/layout/navBars/topBar/index.vue";
import TagsView from "@/layout/navBars/tagsView/tagsView.vue";
import { loginOut } from "@/api/index";

export default {
  name: "layoutNavBars",
  components: { BreadcrumbIndex, TagsView },
  data() {
    return {};
  },
  computed: {
    // 设置是否显示 tagsView
    setShowTagsView() {
      let { layout, isTagsview } = this.$store.state.themeConfig.themeConfig;
      return layout !== "classic" && isTagsview;
    },
  },
  methods: {
    logout() {
      loginOut().then((response) => {
        this.$router.push({ path: "/Login" });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.layout-navbars-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .logout-btn{
	position: absolute;
	top: 6px;
	right: 10px;
  }
}
</style>
